/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 30px;
}

*:focus {
  outline: 0 !important;
}

.bm-menu {
  padding: 2em 1.5em 0;
  font-size: 0.8em;
}
@media (min-width: 375px) {
  .bm-menu {
    font-size: 1em;
  }
}
@media (min-width: 650px) {
  .bm-menu {
    font-size: 1.1em;
  }
}

@media (min-width: 1500px) {
  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.3em;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-burger-bars {
  background: #008742;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-item-list a {
  color: white;
  text-decoration: none;
}
.bm-item-list a:hover {
  color: white;
  opacity: 0.8;
  text-decoration: none;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
