/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  height: 90px;
}

/* Make the image to responsive */
.image {
  object-fit: cover;
  width: 100px;
  height: 80px;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100px;
  height: 80px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #7e1416;
}

.imagen {
  width: 100px;
  height: 80px;
}

/* When you mouse over the container, fade in the overlay icon*/
.imagen:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  color: white;
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.icon:hover {
  color: #eee;
}

/* When you move the mouse over the icon, change color */
.fa-user:hover {
  color: #ddd;
}
