.formShadow {
        -webkit-box-shadow: -1px 15px 51px -5px rgba(0,0,0,0.18);
        -moz-box-shadow: -1px 15px 51px -5px rgba(0,0,0,0.18);
        box-shadow: -1px 15px 51px -5px rgba(0,0,0,0.18); }

.padding-25-perc {
  margin-top: 45%;
  padding: 10px; }

.max-width-25 {
        max-width: 20%; }
