/*LETRA*/

@font-face {
  font-family: Letra;
  src: url(./fonts/AvenirLTStd-Book.otf); }

@font-face {
  font-family: letraBold;
  src: url(./fonts/facebook.otf); }

$letra: Letra;

$letraFace: letraFace;

/*COLORES*/

$negro:          black;
$negrohover:     #363031;

$fondoPantalla: #f2f2f2;

$gris: #6D6E71;
$grisclaro:   #e5e5e6;
$grisclaro2:   #d8d8da;
$grisclaro3:   #cbcbcd;
$grisclaro4:   #bebfc1;
$grisobscuro: #57585b;

$azul:        #2F6D95;
$azulclaro:   #d8e8f3;
$azulobscuro: #255474;

$rojo:        #7E1416;
$rojoclaro:   #f8d3d4;
$rojoobscuro: #580e0f;

$verde:        #008742;
$verdeclaro:   #e6fff2;
$verdeobscuro: #006631;

$amarillo:        #febe12;
$amarilloclaro:   #fef0cd;
$amarilloobscuro: #BF8C0D;
$amarillocalendario: #fed777;

$cafe: #d39314;
$cafeclaro: #febe12;
$cafeobscuro: #febe12;
$blanco: white;

/*----Otras Variables------*/

$opacity: .8;

$micro: 2px;
$muychico: 5px;
$chico: 10px;
$mediano: 15px;
$grande: 20px;
$muygrande: 25px;
$enorme: 30px;
$epico: 150px;

$tmuychica: 10px;
$tchica: 13px;
$tmediana: 16px;
$tgrande: 20px;
$tmuygrande: 25px;
$tenorme: 35px;

$a: .555;

$propiedadChica: 280px;
$propiedadNormal: 300px;


/*CALENDARIO*/
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: $amarillo;
  border: 1px solid $amarillo; }

.CalendarDay__selected_span {
  background: $amarillocalendario;
  border: 1px solid $amarillo;
  color: $blanco;
  &:hover {
    background: $amarillocalendario;
    border: 1px solid $amarilloobscuro;
    color: $blanco; } }

.CalendarDay__hovered_span {
  background: $amarillocalendario;
  border: 1px solid $amarilloobscuro;
  color: $blanco;
  &:hover {
    background: $amarillo;
    border: 1px solid $amarillo;
    color: $blanco; } }

.DateInput_input__focused {
  border-bottom: 2px solid $amarillo; }

.DateInput_input {
  font-size: 10px;
  padding: 0px 10px 0px;
  @media (min-width: 576px) {
    font-size: 10px; } }

.DateInput {
  width: 60px;
  @media (min-width: 576px) {
    width: 70px; }
  @media (min-width: 768px) {
    width: 65px; }
  @media (min-width: 900px) {
    width: 70px; }
  @media (min-width: 1200px) {
    width: 75px; } }


.paddingCalendario {
  padding-top: $muychico;
  padding-left: $chico;
  @media (min-width: 576px) {
    padding-top: 0px; } }

.claseCalendario {
  @media (max-width: 650px) {
    font-size: 13.5px; } }

.botonAzul.claseCalendario.btn.btn-secondary.active {
  background-color: $azulobscuro; }

.botonAmarillo.claseCalendario.btn.btn-secondary.active {
  background-color: $amarilloobscuro; }



/*LETRA*/
body {
  margin: 0;
  padding: 0;
  font-family: $letra;
  background: $fondoPantalla; }


/*COLOR LETRA*/
.negro {
  color: $negro; }

.gris {
  color: $gris; }
.grisClaro {
  color: $grisclaro; }
.grisObscuro {
  color: $grisobscuro; }

.azul {
  color: $azul; }
.azulClaro {
  color: $azulclaro; }
.azulObscuro {
  color: $azulobscuro; }

.rojo {
  color: $rojo; }
.rojoClaro {
  color: $rojoclaro; }
.rojoObscuro {
  color: $rojoobscuro; }

.verde {
  color: $verde; }
.verdeClaro {
  color: $verdeclaro; }
.verdeObscuro {
  color: $verdeobscuro; }

.amarillo {
  color: $amarillo; }
.amarilloClaro {
  color: $amarilloclaro; }
.amarilloObscuro {
  color: $amarilloobscuro; }

.cafe {
  color: $cafe; }
.cafeClaro {
  color: $cafeclaro; }
.cafeObscuro {
  color: $cafeobscuro; }

.blanco {
  color: $blanco; }


/*COLOR FONDO*/
.fondoPantalla {
  background: $fondoPantalla; }

.fondoNegro {
  background: $negro; }

.fondoGris {
  background: $gris; }
.fondoGrisClaro {
  background: $grisclaro; }
.fondoGrisClaro2 {
  background: $grisclaro2; }
.fondoGrisClaro3 {
  background: $grisclaro3; }
.fondoGrisClaro4 {
  background: $grisclaro4; }
.fondoGrisObscuro {
  background: $grisobscuro; }

.fondoAzul {
  background: $azul; }
.fondoAzulClaro {
  background: $azulclaro; }
.fondoAzulObscuro {
  background: $azulobscuro; }

.fondoRojo {
  background: $rojo; }
.fondoRojoClaro {
  background: $rojoclaro; }
.fondoRojoObscuro {
  background: $rojoobscuro; }

.fondoVerde {
  background: $verde; }
.fondoVerdeClaro {
  background: $verdeclaro; }
.fondoVerdeObscuro {
  background: $verdeobscuro; }

.fondoAmarillo {
  background: $amarillo; }
.fondoAmarilloClaro {
  background: $amarilloclaro; }
.fondoAmarilloObscuro {
  background: $amarilloobscuro; }

.fondoCafe {
  background: $cafe; }
.fondoCafeClaro {
  background: $cafeclaro; }
.fondoCafeObscuro {
  background: $cafeobscuro; }

.fondoBlanco {
  background: $blanco; }



/*COLOR LINK  Link sin formato*/
.linkSF {
  text-decoration: none !important; }

.linkNegro {
  a {
    color: $negro;
    text-decoration: none;
    &:hover {
      color: $negrohover; } } }

.linkGris {
  a {
    color: $gris;
    text-decoration: none;
    &:hover {
      color: $grisobscuro; } } }
.linkGrisClaro {
  a {
    color: $grisclaro;
    text-decoration: none;
    &:hover {
      color: $gris; } } }
.linkGrisObscuro {
  a {
    color: $grisobscuro;
    text-decoration: none;
    &:hover {
      color: $gris; } } }

.linkAzul {
  a {
    color: $azul;
    text-decoration: none;
    &:hover {
      color: $azulobscuro; } } }

.linkAzul2 {
  color: $azul;
  &:hover {
    color: $azulobscuro; } }

.linkAzulClaro {
  a {
    color: $azulclaro;
    text-decoration: none;
    &:hover {
          color: $azul; } } }
.linkAzulObscuro {
  a {
    color: $azulobscuro;
    text-decoration: none;
    &:hover {
      color: $azul; } } }

.linkRojo {
  a {
    color: $rojo;
    text-decoration: none;
    &:hover {
      color: $rojoobscuro; } } }

.linkRojoClaro {
  a {
    color: $rojoclaro;
    text-decoration: none;
    &:hover {
      color: $rojo; } } }
.linkRojoObscuro {
  a {
    color: $rojoobscuro;
    text-decoration: none;
    &:hover {
      color: $rojo; } } }

.linkVerde {
  a {
    color: $verde;
    text-decoration: none;
    &:hover {
      color: $verdeobscuro; } } }
.linkVerdeClaro {
  a {
    color: $verdeclaro;
    text-decoration: none;
    &:hover {
      color: $verde; } } }
.linkVerdeObscuro {
  a {
    color: $verdeobscuro;
    text-decoration: none;
    &:hover {
      color: $verde; } } }

.linkAmarillo {
  a {
    color: $amarillo;
    text-decoration: none;
    &:hover {
      color: $amarilloobscuro; } } }
.linkAmarilloClaro {
  a {
    color: $amarilloclaro;
    text-decoration: none;
    &:hover {
      color: $amarillo; } } }
.linkAmarilloObscuro {
  a {
    color: $amarilloobscuro;
    text-decoration: none;
    &:hover {
      color: $amarillo; } } }

.linkCafe {
  a {
    color: $cafe;
    text-decoration: none;
    &:hover {
      color: $cafeobscuro; } } }
.linkCafeClaro {
  a {
    color: $cafeclaro;
    text-decoration: none;
    &:hover {
      color: $cafe; } } }
.linkCafeObscuro {
  a {
    color: $cafeobscuro;
    text-decoration: none;
    &:hover {
      color: $cafe; } } }

.linkBlanco {
  a {
    color: $blanco;
    text-decoration: none;
    &:hover {
      color: $grisclaro; } } }


/*COLOR BOTON - Estilos */
.botonNegro {
  background: $negro;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $negro;
    opacity: $opacity; }
  &:hover {
    background: $blanco;
    color: $negro; } }
.botonNegro2 {
  background: $negro;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $negro;
    opacity:  $opacity; }
  &:hover {
    background: $negrohover; } }

.botonGris {
  background: $gris;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $gris;
    opacity: $opacity; }
  &:hover {
    background: $negro; } }
.botonGrisClaro {
  background: $grisclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $grisclaro;
    opacity: $opacity; }
  &:hover {
    background: $gris;
    color: $blanco; } }

.botonFacebook {
  background: #3D5894;
  border: none;
  font-family: $letraFace;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $azul;
    opacity: $opacity; }
  &:hover {
    background: $azulobscuro; } }

.botonCerrarSesion {
  padding-top: 6px;
  padding-bottom: 4px;
  background: transparent;
  color: $verde;
  border-radius: 5px;
  border-width: 2px;
  border-color: $verde;
  font-weight: bold;
  &:active {
    opacity: .5;
    background: transparent;
    color: $verde;
    border-color: $verde; }
  &:hover {
    opacity: .5;
    background: transparent;
    color: $verde;
    border-color: $verde; } }

.botonAzul {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $azul;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $azul;
    opacity: $opacity; }
  &:hover {
    background: $azulobscuro; } }

.botonAzulClaro {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $azulclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $verde;
    opacity: $opacity; }
  &:hover {
    background: $verdeclaro;
    color: $negro; } }

.botonRojo {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $rojo;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $rojo;
    opacity: $opacity; }
  &:hover {
    background: $rojoobscuro; } }

.botonRojoClaro {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $rojoclaro;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $verde;
    opacity: $opacity; }
  &:hover {
    background: $verdeclaro;
    color: $negro; } }

.botonVerde {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $verde;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $verde;
    opacity: $opacity; }
  &:hover {
    background: $verdeobscuro; } }

.botonAmarillo {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $amarillo;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $amarillo;
    opacity: $opacity; }
  &:hover {
    background: $amarilloobscuro; } }


.botonCafe {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $cafe;
  border: none;
  color: $blanco;
  font-weight: bold;
  &:active {
    background: $cafe;
    opacity: $opacity; }
  &:hover {
    background: $cafeobscuro; } }

.botonTransparente {
  padding-top: 6px;
  padding-bottom: 4px;
  background: transparent;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: transparent;
    color: $blanco; } }

.botonBlanco {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $blanco;
  border: none;
  color: $negro;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $rojo;
    color: $blanco; } }

/*azul*/
.botonBlanco1 {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $blanco;
  border: none;
  color: $azul;
  font-weight: bold;
  &:active {
    background: $verdeclaro;
    opacity: $opacity; }
  &:hover {
    background: $verdeclaro;
    color: $verde; }
  &:focus {
    background: $verdeclaro;
    color: $verde; } }

/*rojo*/
.botonBlanco3 {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $blanco;
  border: none;
  color: $rojo;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $rojo; } }

/*verde*/
.botonBlanco4 {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $blanco;
  border: none;
  color: $verde;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $verde; } }
/*amarillo*/
.botonBlanco2 {
  padding-top: 6px;
  padding-bottom: 4px;
  background: $blanco;
  border: none;
  color: $amarillo;
  font-weight: bold;
  &:active {
    background: $blanco;
    opacity: $opacity; }
  &:hover {
    background: $grisclaro;
    color: $amarillo; } }

.widthbotones {
    width: 205.36px; }

.claseBotonCuadrado {
    width: 42px;
    height: 42px; }

.botonCuadrado {
  height: 150px;
  width: 150px;
  font-size: 20px;
  font-weight: bold; }

.botonCuadrado2 {
  height: 100px;
  width: 100px;
  font-size: 15px;
  font-weight: bold; }

.claseBoton {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px; }

.claseBoton1 {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px; }

.claseBoton2 {
  padding-top: 6px;
  padding-bottom: 3px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px; }

.claseBoton3 {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px; }


.botonArchivo {
  border: 2px solid $azul;
  color: $azul;
  background-color: $blanco;
  padding: 4px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold; }

.botonArchivo-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  input[type=file] {
    font-size: 50px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0; } }


/*BORDER*/
.bordeAmarillo {
  border: 2px solid $amarillo; }

.bordeRojo {
  border: 2px solid $rojo; }

.bordeAzul {
  border: 2px solid $azul; }

.bordeGris {
  border: 2px solid $gris; }

.bordeNegro {
  border: 2px solid $negro; }

.bordeVerde {
  border: 2px solid $verde; }

.bordeBlanco {
  border: 2px solid $blanco; }

/*WIDTH - HEIGHT - BORDER*/
.h100 {
    height: 100%; }
.w100 {
    width: 100%; }
.w75 {
    width: 75%; }
.w50 {
    width: 50%; }
.circulo {
    border-radius: 50%; }

/*MAYUSCULAS Y MINUSCULAS*/
.minus {
  text-transform: lowercase; }

.mayus {
  text-transform: uppercase; }

.pmayus {
  text-transform: capitalize; }



/*PADDING*/
.pmicro {
  padding: 1px; }
.pmuychico {
  padding: 2px; }
.pchico {
  padding: 5px; }
.pmediano {
  padding: 10px; }
.pgrande {
  padding: 15px; }
.pmuygrande {
  padding: 20px; }
.penorme {
  padding: 30px; }

.parmicro {
  padding-top: $micro; }
.parmuychico {
  padding-top: $muychico; }
.parchico {
  padding-top: $chico; }
.parmediano {
  padding-top: $mediano; }
.pargrande {
  padding-top: $grande; }
.parmuygrande {
  padding-top: $muygrande; }
.parenorme {
  padding-top: $enorme; }
.parepico {
  padding-top: $epico; }

.pabmicro {
  padding-bottom: $micro; }
.pabmicro1 {
  padding-bottom: 1px; }
.pabmicro3 {
  padding-bottom: 3px; }
.pabmicro4 {
  padding-bottom: 4px; }
.pabmuychico {
  padding-bottom: $muychico; }
.pabchico {
  padding-bottom: $chico; }
.pabmediano {
  padding-bottom: $mediano; }
.pabgrande {
  padding-bottom: $grande; }
.pabmuygrande {
  padding-bottom: $muygrande; }
.pabenorme {
  padding-bottom: $enorme; }

.pizmicro {
  padding-left: $micro; }
.pizmicro1 {
  padding-left: 1px; }
.pizmicro3 {
  padding-left: 3px; }
.pizmicro4 {
  padding-left: 4px; }
.pizmuychico {
  padding-left: $muychico; }
.pizchico {
  padding-left: $chico; }
.pizmediano {
  padding-left: $mediano; }
.pizgrande {
  padding-left: $grande; }
.pizmuygrande {
  padding-left: $muygrande; }
.pizenorme {
  padding-left: $enorme; }

.pdemicro {
  padding-right: $micro; }
.pdemicro1 {
  padding-right: 1px; }
.pdemicro3 {
  padding-right: 3px; }
.pdemicro4 {
  padding-right: 4px; }
.pdemuychico {
  padding-right: $muychico; }
.pdechico {
  padding-right: $chico; }
.pdemediano {
  padding-right: $mediano; }
.pdegrande {
  padding-right: $grande; }
.pdemuygrande {
  padding-right: $muygrande; }
.pdeenorme {
  padding-right: $enorme; }

.pladosmicro {
  padding-right: $micro;
  padding-left: $micro; }
.pladosmuychico {
  padding-right: $muychico;
  padding-left: $muychico; }
.pladoschico {
  padding-right: $chico;
  padding-left: $chico; }
.pladosmediano {
  padding-right: $mediano;
  padding-left: $mediano; }
.pladosgrande {
  padding-right: $grande;
  padding-left: $grande; }
.pladosmuygrande {
  padding-right: $muygrande;
  padding-left: $muygrande; }
.pladosenorme {
  padding-right: $enorme;
  padding-left: $enorme; }
.pladosresponsivo {
  padding-right: $enorme;
  padding-left: $enorme;
  @media (min-width: 400px) {
    padding-right: 15vw;
    padding-left: 15vw; }
  @media (min-width: 450px) {
    padding-right: 19vw;
    padding-left: 19vw; }
  @media (min-width: 500px) {
    padding-right: 18vw;
    padding-left: 18vw; }
  @media (min-width: 650px) {
    padding-right: 15vw;
    padding-left: 15vw; }
  @media (min-width: 768px) {
    padding-right: 13vw;
    padding-left: 13vw; }
  @media (min-width: 850px) {
    padding-right: 12vw;
    padding-left: 12vw; }
  @media (min-width: 1000px) {
    padding-right: 9vw;
    padding-left: 9vw; }
  @media (min-width: 1250px) {
    padding-right: 8vw;
    padding-left: 8vw; }
  @media (min-width: 1500px) {
    padding-right: 7vw;
    padding-left: 7vw; }
  @media (min-width: 1750px) {
    padding-right: 5vw;
    padding-left: 5vw; } }

.sinpym {
    padding: 0px;
    margin: 0px; }
.sinpymde {
    padding-right: 0px; }
.sinpymiz {
    padding-left: 0px; }
.sinpymar {
    padding-top: 0px;
    margin-top: 0px; }
.sinpymab {
    padding-bottom: 0px;
    margin-bottom: 0px; }
.sinpymlados {
    padding-right: 0px;
    padding-left: 0px; }


/*MARGIN*/
.mmicro {
  margin: 1px; }
.mmuychico {
  margin: 2px; }
.mchico {
  margin: 5px; }
.mmediano {
  margin: 10px; }
.mgrande {
  margin: 15px; }
.mmuygrande {
  margin: 20px; }
.menorme {
  margin: 30px; }

.marmicro {
  margin-top: $micro; }
.marmuychico {
  margin-top: $muychico; }
.marchico {
  margin-top: $chico; }
.marmediano {
  margin-top: $mediano; }
.margrande {
  margin-top: $grande; }
.marmuygrande {
  margin-top: $muygrande; }
.marenorme {
  margin-top: $enorme; }

.mabmicro {
  margin-bottom: $micro; }
.mabmuychico {
  margin-bottom: $muychico; }
.mabchico {
  margin-bottom: $chico; }
.mabmediano {
  margin-bottom: $mediano; }
.mabgrande {
  margin-bottom: $grande; }
.mabmuygrande {
  margin-bottom: $muygrande; }
.mabenorme {
  margin-bottom: $enorme; }

.mizmicro {
  margin-left: $micro; }
.mizmuychico {
  margin-left: $muychico; }
.mizchico {
  margin-left: $chico; }
.mizmediano {
  margin-left: $mediano; }
.mizgrande {
  margin-left: $grande; }
.mizmuygrande {
  margin-left: $muygrande; }
.mizenorme {
  margin-left: $enorme; }

.mdemicro {
  margin-right: $micro; }
.mdemuychico {
  margin-right: $muychico; }
.mdechico {
  margin-right: $chico; }
.mdemediano {
  margin-right: $mediano; }
.mdegrande {
  margin-right: $grande; }
.mdemuygrande {
  margin-right: $muygrande; }
.mdeenorme {
  margin-right: $enorme; }

.mladosmicro {
  margin-right: $micro;
  margin-left: $micro; }
.mladosmuychico {
  margin-right: $muychico;
  margin-left: $muychico; }
.mladoschico {
  margin-right: $chico;
  margin-left: $chico; }
.mladosmediano {
  margin-right: $mediano;
  margin-left: $mediano; }
.mladosgrande {
  margin-right: $grande;
  margin-left: $grande; }
.mladosmuygrande {
  margin-right: $muygrande;
  margin-left: $muygrande; }
.mladosenorme {
  margin-right: $enorme;
  margin-left: $enorme; }

/*LINEA SEPARADORA*/
.lmuychica {
  height: 1px; }
.lchica {
  height: 2px; }
.lmediana {
  height: 3px; }
.lgrande {
  height: 4px; }
.lmuygrande {
  height: 5px; }
.lenorme {
  height: 10px; }


/*TAMAÑO LETRA*/
.tmuychica {
  font-size: $tmuychica; }
.tchica {
  font-size: $tchica; }
.tmediana {
  font-size: $tmediana; }
.tgrande {
  font-size: $tgrande; }
.tmuygrande {
  font-size: $tmuygrande; }
.tenorme {
  font-size: $tenorme; }
.tepica {
  font-size: 120px; }

.tresponsiva {
    font-size: 13px;
    @media (min-width: 650px) {
      font-size: 14px; }
    @media (min-width: 1020px) {
      font-size: 15px; } }


/*FONT WEIGHT*/
.wnormal {
  font-weight: normal; }
.wbold {
  font-weight: bold; }
.wbolder {
  font-weight: bolder; }


/*ALINEAR*/
.centro {
  text-align: center;
  align-items: center; }
.izquierda {
  text-align: left;
  align-items: center; }
.derecha {
  text-align: right;
  align-items: center; }
.justificado {
  text-align: justify;
  text-justify: inter-word; }
.enmedio {
  vertical-align: middle;
  display: inline; }
.abajo {
  vertical-align: bottom; }

/*REDONDEAR*/

.redondo {
  border-radius: 15px; }

.redondoar {
  border-radius: 15px 15px 0px 0px; }

.redondoab {
  border-radius: 0px 0px 15px 15px; }

.redondoiz {
  border-radius: 15px 0px 0px 15px; }

.redondode {
  border-radius: 0px 15px 15px 0px; }

.redondoariz {
  border-radius: 15px 0px 0px 0px; }

.redondoarde {
  border-radius: 0px 15px 0px 0px; }

.redondoabde {
  border-radius: 0px 0px 15px 0px; }

.redondoabiz {
  border-radius: 0px 0px 0px 15px; }


/*INPUTS*/
.letraTamanoChico {
  font-size: 13;
  @media (min-width: 692px) {
    font-size: 15; }
  @media (min-width: 900px) {
    font-size: 16; } }

.addon {
    height: 2.45em !important;
    font-size: 1em !important;
    padding: .5em !important; }

.paddingAddon {
    padding-top: 12px;
    width: 35px;
    padding-right: 1px; }

.paddingAddon2 {
    padding-top: 9px;
    width: 35px;
    padding-right: 1px; }

.paddingAddon3 {
  border-left: 1px $gris solid;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-top: 1px $gris solid;
  border-bottom: 1px $gris solid;
  font-size: $tgrande;
  padding-top: 8px;
  width: 35px;
  padding-right: 1px; }

.borderBlanco {
  border-color: white; }

.borderVerde {
  border-color: $verde; }

.borderRojo {
  border-color: $rojo; }

.borderAzul {
  border-color: $azul; }

.borderGris {
  border-color: $gris; }

/*MERCADO*/
.tamanoProductoImg {
  width: 75px; }

.tamanoProductoImg2 {
  width: 100px; }

.tamanoProductoImg3 {
  width: 150px;
  @media (min-width: 692px) {
    width: 200px; } }

.logoIconos {
  width: 150px;
  @media (min-width: 692px) {
    width: 250px; }
  @media (min-width: 1300px) {
    width: 280px; } }

.tamanoIconos {
  width: 270px;
  @media (min-width: 325px) {
    width: 300px; }
  @media (min-width: 400px) {
    width: 350px; }
  @media (min-width: 692px) {
    width: 500px; }
  @media (min-width: 1300px) {
    width: 600px; } }

.paddingIconos {
  padding-top: 30px;
  @media (min-width: 325px) {
    padding-top: 40px; }
  @media (min-width: 692px) {
    padding-top: 70px; } }



.mercadologo {
  height: 65px;
  padding: 5px;
  padding-left: 1vw; }
.compu {
  width: 100%;
  padding: 5px; }

//inicio scroll scrollHorizontal



.scrollHorizontal {
  overflow: hidden;
  white-space: nowrap; }



.hPage {
  vertical-align: middle;
  display: inline-block; }

//menu

.navHighlight {
  color: rgba(0, 0, 0, 1);
  &:hover {
    color: $rojo;
    font-weight: bold; }
  &:active {
    color: $rojo;
    font-weight: bold; } }

//inicio
//chevrons en centro pantalla mediana a grande
#naveg {
  position: absolute;
  margin: 0;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(50%); }

//chevrons abajo pantalla chica
#naveg2 {
  position: sticky;
  margin: 0;
  height: 100%;
  bottom: 0px;
  -ms-transform: translateY(-90%);
  transform: translateY(90%); }

.transparente {
  background: rgba(255,255,255, 0.7); }


.transparente2 {
  background: rgba(255,255,255, 0.85); }

//card de inicio transparente en centro
#naveg3 {
  position: absolute;
  margin: 0;
  height: 100%;
  top: 1vw;
  -ms-transform: translateY(-19%);
  transform: translateY(19%);
  @media (min-width: 692px) {
    top: 1vw;
    -ms-transform: translateY(-25%);
    transform: translateY(25%); }
  @media (min-width: 900px) {
    top: 1vw;
    -ms-transform: translateY(-20%);
    transform: translateY(20%); }
  @media (min-width: 1367px) {
    top: 1vw;
    -ms-transform: translateY(-29%);
    transform: translateY(29%); } }
//pantalla menosxs 576xs 768sm, 992md, 1200lg

.naveg4 {
  bottom: 100px;
  position: absolute;
  left: 50%;
  margin-left: -15px; }

#cardInicio {
  position: absolute;
  margin: 0;
  height: 100%;
  top: 1vw;
  -ms-transform: translateY(-19%);
  transform: translateY(19%);
  padding-top: $enorme;
  text-align: center;
  @media (min-width: 692px) {
    top: 1vw;
    -ms-transform: translateY(-25%);
    transform: translateY(25%); }
  @media (min-width: 900px) {
    top: 1vw;
    -ms-transform: translateY(-20%);
    transform: translateY(20%); }
  @media (min-width: 992px) {
    position: absolute;
    padding-top: 0px;
    top: 0vw;
    -ms-transform: translateY(-0%);
    transform: translateY(0%);
    transform: translateX(100%);
    width: 45vw; }
  @media (min-width: 1150px) {
    transform: translateX(120%);
    width: 41vw; }
  @media (min-width: 1350px) {
    transform: translateX(150%);
    width: 35vw; }
  @media (min-width: 1650px) {
    transform: translateX(250%);
    width: 27vw; } }

.alturaCardInicio {
  padding-top: $mediano;
  @media (min-width: 992px) {
    height: 100%;
    padding-top: 20vw;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px; }
  @media (min-width: 1250px) {
    padding-top: 13vw; }
  @media (min-width: 1550px) {
    padding-top: 10vw; } }

.textoInicio {
  font-size: 25px;
  @media (min-width: 560px) {
    font-size: 30px; }
  @media (min-width: 692px) {
    font-size: 27px; }
  @media (min-width: 900px) {
    font-size: 35px; }
  @media (min-width: 992px) {
    font-size: 42px; } }

.textoInicio2 {
  font-size: 24px;
  @media (min-width: 560px) {
    font-size: 27px; }
  @media (min-width: 692px) {
    font-size: 28px; }
  @media (min-width: 900px) {
    font-size: 31px; }
  @media (min-width: 992px) {
    font-size: 29px; }
  @media (min-width: 1075px) {
    font-size: 31px; }
  @media (min-width: 1220px) {
    font-size: 33px; }
  @media (min-width: 1325px) {
    font-size: 35px; }
  @media (min-width: 1350px) {
    font-size: 31px; }
  @media (min-width: 1420px) {
    font-size: 34px; }
  @media (min-width: 1575px) {
    font-size: 37px; }
  @media (min-width: 1650px) {
    font-size: 29px; }
  @media (min-width: 1750px) {
    font-size: 32px; } }

.chevronInicio {
  color: $blanco;
  opacity: 1;
  &:hover {
    color: $grisclaro;
    opacity: .7; }
  &:focus {
    opacity: 0; } }

.botonInicio {
  font-size: 16px;
  @media (min-width: 692px) {
    font-size: 23px; }
  @media (min-width: 900px) {
    font-size: 28px; } }

.botonRegistrate {
  font-size: 15px !important; }

.textoEpico {
  font-size: 45px;
  @media (min-width: 900px) {
    font-size: 60px; } }


.paddingPantallaEnorme {
  @media (min-width: 1600px) {
    padding-top: 4vw; } }

// seccion2 porque somos tu mejor opcion
//pantalla menosxs 576xs 768sm, 992md, 1200lg

.paddingSeccion {
  padding-left: 1vw;
  padding-right: 2vw;
  padding-top: $muychico;
  @media (min-width: 360px) {
    padding-top: $chico; }
  @media (min-width: 414px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 450px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 500px) {
    padding-top: $mediano;
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 576px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 992px) {
    padding-top: $muygrande; }
  @media (min-width: 1200px) {
    padding-top: $enorme; } }


.paddingPorque {
  padding-top: 0px;
  height: 85px;
  @media (min-width: 360px) {
    height: 100px; }
  @media (min-width: 400px) {
    height: 130px; }
  @media (min-width: 768px) {
    height: 210px;
    padding-top: $enorme; } }


.letraPorque {
  font-size: 8.5vw;
  line-height: 9.5vw;
  @media (min-width: 400px) {
    font-size: 9.5vw;
    line-height: 10.2vw; }
  @media (min-width: 450px) {
    font-size: 9vw;
    line-height: 9.7vw; }
  @media (min-width: 500px) {
    font-size: 7vw;
    line-height: 7.7vw; }
  @media (min-width: 576px) {
    font-size: 35px; }
  @media (min-width: 768px) {
    font-size: 5vw;
    line-height: 5.7vw;
    padding-right: 5vw; }
  @media (min-width: 800px) {
    font-size: 4.5vw;
    line-height: 5.2vw; }
  @media (min-width: 900px) {
    font-size: 4.3vw;
    line-height: 5vw; }
  @media (min-width: 1000px) {
    font-size: 4vw;
    line-height: 4.7vw; }
  @media (min-width: 1200px) {
    font-size: 45px;
    line-height: 50px; } }

.opcionesPorque {
  font-size: 12px {
    line-height: 14px; }
  @media (min-width: 360px) {
    font-size: 14px;
    line-height: 16px; }
  @media (min-width: 400px) {
    font-size: 16px;
    line-height: 18px; }
  @media (min-width: 768px) {
    padding-right: 5vw; }
  @media (min-width: 992px) {
    padding-right: 5vw;
    font-size: 22px;
    line-height: 26px; } }

.parOpcionesPorque {
  padding-top: $muychico;
  @media (min-width: 360px) {
    padding-top: $chico; } }

//animaciones facil
.fadeIn1 {
  animation-name: mymove;
  animation-duration: 3s; }

.fadeIn2 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn3 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn4 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn5 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }

.fadeIn6 {
  opacity: 0;
  margin-bottom: 5%;
  padding-left: 10%;
  @media (min-width: 420px) {
    padding-left: 15%; }
  @media (min-width: 1800px) {
    padding-left: 19%; } }


// otros
.imagenHover {
  opacity: .7;
  &:hover {
    opacity: 1; } }

.textoImagen {
  position: relative;
  text-align: center;
  color: white; }

.centrado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.centradoIcono {
  position: relative;
  top: 2px; }

.iconoBoton {
  opacity: .5;
  &:hover {
    opacity: 1; }
  &:active {
    color: $rojo; } }

.subrayado {
  text-decoration: underline; }

.borderRAzul {
  border-right: 3px solid;
  border-color: $azul; }

.borderLAzul {
  border-left: 3px solid;
  border-color: $azul; }

.borderRVerde {
  border-right: 3px solid;
  border-color: $verde; }

.borderRRojo {
  border-right: 3px solid;
  border-color: $rojo; }

.borderRAmarillo {
  border-right: 3px solid;
  border-color: $amarillo; }

.borderRBlanco {
  border-right: 3px solid;
  border-color: $blanco; }

.borderRGris {
  border-right: 3px solid;
  border-color: $gris; }

.borderLGris {
  border-left: 3px solid;
  border-color: $gris; }

.claseHover {
  opacity: 1;
  &:hover {
    opacity: .6; } }

.claseHover2 {
  opacity: .6;
  &:hover {
    opacity: 1; } }

.deinicio {
  @media (min-width: 768px) {
    padding-right: 3vw; } }

.pizinicio {
  @media (min-width: 768px) {
    padding-left: 3vw; } }

.parinicio {
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 1px; } }

.parinicio2 {
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 29px; } }

.parinicio3 {
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 16px; } }

.estrellas {
  font-size: $muygrande;
  padding-top: 8px;
  @media (min-width: 576px) {
    font-size: $enorme;
    padding-top: 0px; } }

.comensales {
  @media (min-width: 992px) {
    text-align: right; }
  @media (min-width: 1250px) {
    text-align: center; } }

.letraRecibidos {
  font-size: 13px;
  @media (min-width: 768px) {
    font-size: 12px; }
  @media (min-width: 820px) {
    font-size: 13px; } }

.tcompras {
  font-size: 16px;
  @media (min-width: 576px) {
    font-size: 20px; }
  @media (min-width: 768px) {
    font-size: 16px; }
  @media (min-width: 980px) {
    font-size: 20px; } }

.tmes {
  font-size: 12px;
  @media (min-width: 576px) {
    font-size: 16px; }
  @media (min-width: 768px) {
    font-size: 12px; }
  @media (min-width: 980px) {
    font-size: 16px; } }

.tletra {
  font-size: 13px;
  @media (min-width: 576px) {
    font-size: 16px; } }

.textoImagen2 {
  opacity: 0;
  &:hover {
    opacity: 1; } }

.dato {
  color: #90949c; }

.underline {
  text-decoration: underline; }

.normal {
  text-decoration: none; }

.bottom-right {
  position: absolute;
  bottom: 10%;
  right: 5%; }

.contenedor {
  position: relative;
  text-align: center; }

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.vertical {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.letraIniciar {
  font-size: 13px;
  @media (min-width: 501px) {
    font-size: 16px; } }

// registrate

.alturaRegistro {
  height: 85px;
  @media (min-width: 576px) {
    height: 91px; } }

.alturaRegistro2 {
  height: 60px;
  text-align: center;
  @media (min-width: 576px) {
    height: 91px; } }

.paddingRegistro {
  padding-top: 5px;
  @media (min-width: 576px) {
    padding-top: $muygrande;
    font-size: 29px; }
  @media (min-width: 660px) {
    font-size: 34px; }
  @media (min-width: 768px) {
    font-size: 31px; }
  @media (min-width: 830px) {
    font-size: 35px; } }

.xspiz {
  padding-right: 0px;
  padding-left: $grande; }

.xspde {
  padding-right: $muychico;
  padding-left: 0px; }

.xsemail {
  padding-right: $muychico;
  padding-left: 0px;
  @media (min-width: 576px) {
    padding-right: 0px;
    padding-left: $grande; }
  @media (min-width: 768px) {
    padding-right: 0px; } }

.smpiz {
  @media (min-width: 576px) {
    padding-right: 0px;
    padding-left: $grande; }
  @media (min-width: 768px) {
    padding-right: 7px; } }

.smpce {
  @media (min-width: 576px) {
    padding-right: 0px;
    padding-left: 0px; }
  @media (min-width: 768px) {
    padding-right: 7px;
    padding-left: 14px; } }


.smpde {
  @media (min-width: 576px) {
    padding-right: $grande;
    padding-left: 0px; }
  @media (min-width: 768px) {
    padding-left: 7px; } }

.alineacionCalendario {
  text-align: center;
  @media (min-width: 576px) {
    text-align: left; } }

.imagenProducto {
  width: 60px;
  max-height: 60px;
  @media (min-width: 650px) {
    width: 100px;
    max-height: 100px; } }

.paddingxs {
  @media (max-width: 768px) {
    padding-top: $muygrande; } }

.paddingmd {
  @media (min-width: 768px) {
    padding-top: $chico; } }



.paddingCategoria {
  padding-top: 8px; }

.paddingPendientes {
  padding-left: $mediano;
  @media (min-width: 576px) {
    padding-left: $enorme; } }

.paddingFlecha {
  padding-top: 2px; }

.paddingconFlecha {
  padding-bottom: 2px; }

.tamanoGuardar {
  width: 350px;
  font-size: $tmediana;
  @media (min-width: 420px) {
    width: 350px;
    font-size: $tgrande; } }

.tamanoCamion {
  font-size: 25px;
  @media (min-width: 420px) {
    font-size: $tmuygrande; } }

.tamanoBotonGuardar {
  font-size: 25px;
  @media (min-width: 420px) {
    font-size: $tmuygrande; } }

.muychicaachica {
  font-size: $tmuychica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 768px) {
    font-size: $tmuychica; }
  @media (min-width: 992px) {
    font-size: 11px; }
  @media (min-width: 1100px) {
    font-size: 12px; }
  @media (min-width: 1300px) {
    font-size: 13px; } }

.chicaamediana {
  font-size: $tchica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 992px) {
    font-size: $tmediana; }
  @media (min-width: 1100px) {
    font-size: $tmediana; }
  @media (min-width: 1300px) {
    font-size: $tmediana; } }

.paddingSociosInicio {
  padding-top: 20px;
  @media (min-width: 768px) {
    padding-top: 0px; } }

.botonCardAmarillo {
  color: $amarillo;
  font-weight: bold;
  border: 2px solid $amarillo;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 2px solid $amarilloobscuro; }
  &:focus {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 2px solid $amarilloobscuro; }
  &:active {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 2px solid $amarilloobscuro; } }

.botonCardAzul {
  color: $azul;
  font-weight: bold;
  border: 2px solid $azul;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; }
  &:focus {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; }
  &:active {
    background-color: $azulclaro;
    color: $azulobscuro;
    border: 2px solid $azulobscuro; } }

.botonCardAmarillo2 {
  color: $amarillo;
  font-weight: bold;
  border: 0px solid $amarillo;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 0px;
  &:hover {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 0px solid $amarilloobscuro; }
  &:focus {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 0px solid $amarilloobscuro; }
  &:active {
    background-color: $amarilloclaro;
    color: $amarilloobscuro;
    border: 0px solid $amarilloobscuro; } }

.letraClientes {
  font-size: 14px;
  margin-top: 2px; }

.tventas {
  font-size: 13px;
  padding-top: 15px;
  @media (min-width: 576px) {
    padding-top: 13px;
    font-size: 20px; }
  @media (min-width: 768px) {
    padding-top: 14px;
    font-size: 16px; }
  @media (min-width: 992px) {
    font-size: 17px; } }

.claseBuscador {
  font-size: $tmuychica;
  @media (min-width: 576px) {
    font-size: $tchica; }
  @media (min-width: 768px) {
    font-size: $tmediana; } }

.paddingBuscadorArea {
  padding-top: 6px;
  @media (min-width: 576px) {
    padding-top: $micro; }
  @media (min-width: 768px) {
    padding-top: $chico; } }

.paddingBuscadorArea2 {
  padding-top: 0px;
  @media (min-width: 576px) {
    padding-top: $micro; }
  @media (min-width: 768px) {
    padding-top: 8px; } }

.paddingTicket {
  @media (min-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px; } }


.inputClientes {
  font-size: 1.2em !important;
  padding: .4em !important;
  height: 2.45em !important;
  @media (min-width: 992px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.4em !important; } }

.parmanzana {
  padding-top: 0px;
  @media (min-width: 768px) {
    padding-top: $micro; } }

.parPlus {
  padding-top: $muychico;
  @media (min-width: 768px) {
    padding-top: $micro; } }

.letraBuscador {
  font-size: .8em !important;
  padding: .5em !important;
  height: 35px !important;
  @media (min-width: 576px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.45em !important; }
  @media (min-width: 768px) {
    font-size: 1em !important;
    padding: .5em !important;
    height: 2.75em !important; } }

.botonLink {
     background: none !important;
     color: inherit;
     border: none;
     padding: 0 !important;
     font: inherit;
     /*border is optional*/
     border-bottom: none;
     cursor: pointer;
     &:hover {
      color: $negro; } }

.productoLista {
  width: 65px;
  max-height: 65px;
  @media (min-width: 576px) {
    width: 75px;
    max-height: 75px; }
  @media (min-width: 768px) {
    width: 80px;
    max-height: 80px; } }

.tproducto {
  font-size: $tchica;
  @media (min-width: 576px) {
    font-size: 14px; }
  @media (min-width: 768px) {
    font-size: 15px; }
  @media (min-width: 992px) {
    font-size: 16px; } }

.parmicro1 {
  padding-top: 1px; }

.parmicro2 {
  padding-top: 3px; }

.parmicro3 {
  padding-top: 4px; }

.intentoBorder {
  border-bottom: 2px $negro solid;
  border-right: 2px $negro solid;
  border-left: 2px $negro solid;
  border-radius: 0px 0px 5px 5px; }


.par1 {
  padding-top: 1px; }

.par2 {
  padding-top: 2px; }

.tamanoBotonBorrar {
  width: 64.45px;
  height: 36px; }

.claseClientes {
  border-radius: 8px 8px 0px 0px; }

.nocentro {
  text-align: left;
  @media (min-width: 576px) {
    text-align: center; } }

.sinpymizsm {}
@media (min-width: 576px) {
    padding-left: 0px; }

.my-custom-scrollbar {
  position: relative;
  height: 77vh;
  overflow: auto; }

.table-wrapper-scroll-y {
  display: block; }

.fondo {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/client-inmobiliaria-vender-casa-o-departamento.jpg");
  background-size: 260%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }

.fondo2 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/imagen-de-client-inmobiliaria-vende-tus-propiedades.jpg");
  background-size: 260%;
  filter: opacity(30%);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  z-index: 9998;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 130%;
    background-position: 15% 0%; } }

.fondo2Propiedad {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/imagen-de-client-inmobiliaria-vende-tus-propiedades.jpg");
  background-size: 260%;
  filter: opacity(10%);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  z-index: 9998;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 130%;
    background-position: 15% 0%; } }

.fondo3 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/client-blanco-y-negro-inmobiliaria-escritorio.jpg");
    background-size: 260%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    @media (min-width: 375px) {
      background-size: 290%; }
    @media (min-width: 400px) {
      background-size: 265%;
      background-position: 50% 10%; }
    @media (min-width: 512px) {
      background-size: 250%;
      background-position: 50% 50%; }
    @media (min-width: 768px) {
      background-size: 190%;
      background-position: 50% 0%; }
    @media (min-width: 1000px) {
      background-size: 150%;
      background-position: 50% 0%; }
    @media (min-width: 1300px) {
      background-size: 130%;
      background-position: 15% 0%; }
    @media (min-width: 1600px) {
      background-size: 100%;
      background-position: 15% 0%; } } }

.fondo4 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/6.png");
    background-size: 260%;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .6;
    @media (min-width: 375px) {
      background-size: 290%; }
    @media (min-width: 400px) {
      background-size: 265%;
      background-position: 50% 10%; }
    @media (min-width: 512px) {
      background-size: 250%;
      background-position: 50% 50%; }
    @media (min-width: 768px) {
      background-size: 190%;
      background-position: 50% 0%; }
    @media (min-width: 1000px) {
      background-size: 150%;
      background-position: 50% 0%; }
    @media (min-width: 1300px) {
      background-size: 130%;
      background-position: 15% 0%; }
    @media (min-width: 1600px) {
      background-size: 100%;
      background-position: 15% 0%; } } }

.fondo5 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/mujer-y-hombre-dandose-la-mano-client-inmobiliaria.jpg");
  background-size: 260%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }

.fondo6 {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/9.jpg");
  background-size: 260%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 290%; }
  @media (min-width: 400px) {
    background-size: 265%;
    background-position: 50% 10%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 50%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }

.fondoIniciar {
  background-image: url("https://e-client.s3.amazonaws.com/imagenes-client/2-brillo.jpg");
  background-size: 280%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  @media (min-width: 375px) {
    background-size: 310%; }
  @media (min-width: 400px) {
    background-size: 295%;
    background-position: 50% 0%; }
  @media (min-width: 512px) {
    background-size: 250%;
    background-position: 50% 0%; }
  @media (min-width: 768px) {
    background-size: 190%;
    background-position: 50% 0%; }
  @media (min-width: 1000px) {
    background-size: 150%;
    background-position: 50% 0%; }
  @media (min-width: 1300px) {
    background-size: 130%;
    background-position: 15% 0%; }
  @media (min-width: 1600px) {
    background-size: 100%;
    background-position: 15% 0%; } }


.alturaCardInicio2 {
  padding-top: $mediano;
  @media (min-width: 992px) {
    height: 100%;
    padding-top: 20vw;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px; }
  @media (min-width: 1250px) {
    padding-top: 13vw; }
  @media (min-width: 1550px) {
    padding-top: 10vw; } }

.posicionCard {
  position: absolute;
  z-index: 9999;
  width: 93%;
  top: -30px;
  padding-left: 25px;
  padding-right: 25px;
  @media (min-width: 950px) {
    padding-left: 50px;
    padding-right: 50px; }
  @media (min-width: 1300px) {
    padding-left: 100px;
    padding-right: 100px;
    width: 96%; }
  @media (min-width: 1600px) {
    padding-left: 150px;
    padding-right: 150px; } }

.posicionCardPropiedad {
  position: absolute;
  z-index: 9999;
  width: 93%;
  padding-top: 8vw;
  padding-left: 25px;
  padding-right: 25px;
  @media (min-width: 950px) {
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 50px; }
  @media (min-width: 1000px) {
    padding-top: 6.5vw; }
  @media (min-width: 1150px) {
    padding-top: 8vw; }
  @media (min-width: 1300px) {
    padding-top: 100px;
    padding-right: 130px;
    width: 96%; }
  @media (min-width: 1400px) {
    padding-top: 120px; }
  @media (min-width: 1600px) {
    padding-right: 250px;
    padding-top: 140px; } }

.claseCardIntento {
  padding-top: 30px;
  @media (min-width: 375px) {
    padding-top: 0px; } }

.linkInicio {
  color: $gris !important; }

.tclientemuestra {
  font-size: 18px; }

.opacity50 {
  opacity: .5; }

.areaOpacityClient {
  background-color: rgba(0,135,66,0.8);
  height: 420px;
  @media (min-width: 375px) {
    height: 500px; }
  @media (min-width: 414px) {
    height: 580px; }
  @media (min-width: 768px) {
    height: 800px; }
  @media (min-width: 890px) {
    height: 600px; } }

.areaOpacityClient2 {
  background-color: rgba(109,110,113,0.8);
  height: 70px;
  width: 110%;
  @media (min-width: 414px) {
    width: 107%; }
  @media (min-width: 768px) {
    height: 100px;
    width: 105%; }
  @media (min-width: 1000px) {
    height: 120px;
    width: 102%; } }

.contenedor2 {
  text-align: center; }

.bordeLogin {
  border-left: 3px solid $verde;
  border-bottom: 3px solid $verde; }

.centered2 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%); }

.letraClient {
  color: rgba(255,255,255, 1);
  z-index: 95; }

.tareaClient {
  font-size: 16px;
  padding-left: $grande;
  padding-right: $grande;
  padding-top: $mediano;
  @media (min-width: 768px) {
    font-size: 22px;
    padding-left: $enorme;
    padding-right: $enorme; }
  @media (min-width: 1300px) {
    text-align: center;
    padding-left: 10vw;
    padding-right: 10vw; } }

.linkRojo2 {
  color: $gris;
  &:hover {
    opacity: .7; } }

.letraPreguntasClient {
  font-size: 25px;
  padding-left: 50px;
  padding-right: $grande;
  padding-top: 10px;
  @media (min-width: 768px) {
    padding-top: 20px;
    font-size: 30px;
    padding-right: $enorme; } }

.bordeRojoPreguntas {
  border-left: 3px solid $rojo;
  border-bottom: 3px solid $rojo;
  padding-top: 60px;
  @media (min-width: 768px) {
    padding-top: 90px; }
  @media (min-width: 1000px) {
    padding-top: 110px; } }

.fondoGrisOpacity {
  background-color: $gris;
  opacity: .8; }

.tletrapregunta {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px; }
  @media (min-width: 1000px) {
    font-size: 18px; } }

.trespuesta {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px; }
  @media (min-width: 1000px) {
    font-size: 16px; } }

.letraLeadHora {
  font-size: 15px; }

.letraLeadPresupuesto {
  font-size: 15px;
  padding-top: 6px; }


.letraLeadDatos {
  font-size: 16px;
  padding-top: 3px;
  @media (min-width: 768px) {
    font-size: $tgrande; } }

.widthRemoverBoton {
  width: 160.81px; }

.bottom {
  position: absolute;
  bottom: 0px;
  width: 96%; }

.bottomPropiedad {
  position: absolute;
  bottom: 25px;
  width: 96%; }

.widthLogo {
  width: 85px; }


.contenedor3 {
  position: relative; }


.heightLead {
  width: 290px;
  height: 365px;
  padding-bottom: 50px;
  margin: auto;
  @media (min-width: 375px) {
    width: 330px;
    height: 325px; }
  @media (min-width: 410px) {
    width: 360px;
    height: 340px; } }

.alturaLead {
  height: 320px;
  @media (min-width: 375px) {
    height: 340px; }
  @media (min-width: 410px) {
    height: 355px; } }

.paddingLeads {
  @media (min-width: 375px) {
    padding-left: 1vw;
    padding-right: 1vw; }
  @media (min-width: 768px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 820px) {
    padding-left: 5vw;
    padding-right: 5vw; }
  @media (min-width: 900px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 991px) {
    padding-left: 11vw;
    padding-right: 11vw; }
  @media (min-width: 1025px) {
    padding-left: 13vw;
    padding-right: 13vw; }
  @media (min-width: 1150px) {
    padding-left: 15vw;
    padding-right: 15vw; }
  @media (min-width: 1200px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 1275px) {
    padding-left: 5vw;
    padding-right: 5vw; }
  @media (min-width: 1350px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 1425px) {
    padding-left: 10vw;
    padding-right: 10vw; }
  @media (min-width: 1500px) {
    padding-left: 12vw;
    padding-right: 12vw; }
  @media (min-width: 1600px) {
    padding-left: 15vw;
    padding-right: 15vw; }
  @media (min-width: 1700px) {
    padding-left: 16vw;
    padding-right: 16vw; }
  @media (min-width: 1800px) {
    padding-left: 18vw;
    padding-right: 18vw; } }

.heightLeadAceptados {
  width: 290px;
  height: 540px;
  margin: auto;
  @media (min-width: 375px) {
    width: 325px;
    height: 510px; }
  @media (min-width: 768px) {
    width: 310px;
    height: 490px; } }

.heightLeadAceptadosChico {
  margin: auto;
  width: 290px;
  @media (min-width: 375px) {
    width: 325px; }
  @media (min-width: 768px) {
    width: 310px; } }

.areaPresupuestoLeadAceptadosChico {
  margin-left: 8px;
  margin-bottom: 10px;
  padding-top: 5px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width: 270px;
  @media (min-width: 375px) {
    width: 305px; }
  @media (min-width: 768px) {
    width: 290px; } }

.alturaLeadAceptados {
  height: 555px;
  @media (min-width: 375px) {
    height: 525px; }
  @media (min-width: 768px) {
    height: 505px; } }

.paddingLeadsAceptados {
  @media (min-width: 375px) {
    padding-left: 1vw;
    padding-right: 1vw; }
  @media (min-width: 768px) {
    padding-left: 7vw;
    padding-right: 7vw; }
  @media (min-width: 992px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 1025px) {
    padding-left: 4vw;
    padding-right: 4vw; }
  @media (min-width: 1150px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 1200px) {
    padding-left: 10vw;
    padding-right: 10vw; }
  @media (min-width: 1350px) {
    padding-left: 12vw;
    padding-right: 12vw; }
  @media (min-width: 1500px) {
    padding-left: 15vw;
    padding-right: 15vw; }
  @media (min-width: 1600px) {
    padding-left: 18vw;
    padding-right: 18vw; }
  @media (min-width: 1700px) {
    padding-left: 20vw;
    padding-right: 20vw; }
  @media (min-width: 1800px) {
    padding-left: 22vw;
    padding-right: 22vw; } }

.tfoto {
  height: 200px;
  object-fit: cover;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

// propiedad
.sombraPropiedad {
  -webkit-box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  -moz-box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  border-radius: 4px; }

.tpropiedad {
  height: 385px;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.tmipropiedad {
  height: 415px;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.fondoBlancoOpacity {
  background: rgba(255, 255, 255, 0.85); }

.posicionAbsoluta {
  position: absolute;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.posicionRenta {
  position: absolute;
  top: 199px;
  left: 23px;
  height: 25px;
  width: 73px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 18px; }

.posicionRentaPublica {
  position: absolute;
  top: 199px;
  left: 23px;
  height: 25px;
  width: 73px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 18px; }

.pladoschico2 {
  padding-left: 7px;
  padding-right: 7px; }

.redondeadoArribaPropiedad {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.botonEditarPropiedad {
  width: 30px;
  height: 30px; }

.alturaPropiedad {
  height: 410px; }

.alturaMiPropiedad {
  height: 435px; }

.posicionBotones {
  position: absolute;
  bottom: 12px;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.botonContactar {
  height: 26px;
  width: 137px; }

.botonContactarTelefono {
  width: 55px; }

.botonCancelar {
  width: 120px;
  padding-right: 0.15rem;
  padding-left: 0.15rem;
  @media (min-width: 576px) {
    width: 100px; } }

.widthModalContactar {
  width: 250px;
  left: 50%;
  margin-left: -30%;
  top: 150px;
  @media (min-width: 576px) {
    top: 0px;
    left: 0%;
    width: 300px; }
  @media (min-width: 768px) {
    top: 13vw; } }

.tpropiedadAutorizar {
  height: 588px;
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.alturaPropiedadAutorizar {
  height: 600px; }

.botonVerMasPropiedades {
  width: $propiedadNormal;
  @media (min-width: 1200px) {
    width: $propiedadChica; }
  @media (min-width: 1270px) {
    width: $propiedadNormal; } }

.paddingPropiedades {
  @media (min-width: 375px) {
    padding-left: 1vw;
    padding-right: 1vw; }
  @media (min-width: 768px) {
    padding-left: 7vw;
    padding-right: 7vw; }
  @media (min-width: 992px) {
    padding-left: 2vw;
    padding-right: 2vw; }
  @media (min-width: 1025px) {
    padding-left: 4vw;
    padding-right: 4vw; }
  @media (min-width: 1150px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 1200px) {
    padding-left: 1vw;
    padding-right: 1vw; }
  @media (min-width: 1350px) {
    padding-left: 4vw;
    padding-right: 4vw; }
  @media (min-width: 1500px) {
    padding-left: 6vw;
    padding-right: 6vw; }
  @media (min-width: 1600px) {
    padding-left: 10vw;
    padding-right: 10vw; }
  @media (min-width: 1700px) {
    padding-left: 12vw;
    padding-right: 12vw; }
  @media (min-width: 1800px) {
    padding-left: 15vw;
    padding-right: 15vw; }
  @media (min-width: 1900px) {
    padding-left: 17vw;
    padding-right: 17vw; } }


.letraChicaBoton {
  font-size: .9em !important;
  height: 2em !important; }

.pizMas {
  padding-left: 7px; }

.logoRegistrate {
  height: 110px; }

.inputIniciar {
  width: 250px;
  @media (min-width: 992px) {
    width: 300px; } }

.logoPosicion {
  padding-top: 25px;
  padding-bottom: 25px;
  @media (min-width: 375px) {
    padding-top: $enorme;
    padding-bottom: 40px; } }

.logoTerminos {
  height: 100px; }


// lead
.sombraTodo {
  -webkit-box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  -moz-box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  box-shadow: 0px 0px 7px 1px rgba(125,125,125,0.57);
  border-radius: 10px; }

.borderPrecio {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.botonComprar {
  background: $verde;
  border: none;
  color: $blanco;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 1px;
  padding-left: 13px;
  padding-right: 15px;
  border-radius: 4px;
  &:active {
    background: $verde;
    opacity: $opacity; }
  &:hover {
    background: $verdeobscuro; } }

.botonEliminarCarrito {
  background: $azul;
  border: none;
  color: $blanco;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 1px;
  padding-left: 13px;
  padding-right: 15px;
  border-radius: 4px;
  &:active {
    background: $azul;
    opacity: $opacity; }
  &:hover {
    background: $azulobscuro; } }

.tprecio {
  font-size: 18px; }

.pabprecio {
  padding-bottom: 20px; }

.tContenidoLead {
  font-size: 14px; }


// alturaLeadAceptados
.botonFinalizar {
  width: 100%; }

// inicio logeado
.coverInicio {
  height: 150px;
  width: 270px;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  @media (min-width: 400px) {
    height: 225px;
    width: 350px; }
  @media (min-width: 768px) {
    height: 225px;
    width: 325px; }
  @media (min-width: 800px) {
    height: 225px;
    width: 350px; }
  @media (min-width: 992px) {
    height: 260px;
    width: 400px; } }

.imagenInicio {
  height: 150px;
  width: 270px;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  opacity: .7;
  @media (min-width: 400px) {
    height: 225px;
    width: 350px; }
  @media (min-width: 768px) {
    height: 225px;
    width: 325px; }
  @media (min-width: 800px) {
    height: 225px;
    width: 350px; }
  @media (min-width: 992px) {
    height: 260px;
    width: 400px; } }

.tamanoInicio {
  width: 290px;
  margin: auto;
  @media (min-width: 400px) {
    width: 370px; }
  @media (min-width: 768px) {
    width: 345px; }
  @media (min-width: 800px) {
    width: 370px; }
  @media (min-width: 992px) {
    width: 420px; } }

.textoImagenInicio {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 25px;
  @media (min-width: 400px) {
    font-size: 33px; }
  @media (min-width: 992px) {
    font-size: 36px; } }

.textoImagenInicioClientes {
  top: 40%;
  left: 18%;
  @media (min-width: 400px) {
    left: 16.5%; }
  @media (min-width: 992px) {
    left: 17%; } }

.textoImagenInicioPropiedades {
  top: 40%;
  left: 9%;
  @media (min-width: 400px) {
    left: 7.5%; }
  @media (min-width: 992px) {
    top: 40%;
    left: 10%; } }

.textoImagenInicioSubirPropiedad {
  top: 27%;
  left: 2%;
  right: 2%;
  @media (min-width: 400px) {
    top: 29%; } }

.textoImagenInicioEstadisticas {
  top: 40%;
  left: 26%;
  @media (min-width: 400px) {
    top: 43%;
    left: 25%; }
  @media (min-width: 992px) {
    left: 28%; } }

.botonChevron {
  background: transparent;
  border: none;
  color: $negro;
  font-weight: bold;
  padding-top: 6px;
  font-size: 20px;
  padding-bottom: 6px;
  &:active {
    opacity: $opacity; }
  &:hover {
    background: transparent;
    opacity: .6;
    color: $negro; }
  @media (min-width: 992px) {
    font-size: 22px; } }

.letraTituloInicio {
  font-size: 16px;
  @media (min-width: 992px) {
    font-size: 17px; } }

.letraSubtituloInicio {
  font-size: 15px; }

.tBienvenido {
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  @media (min-width: 400px) {
    font-size: 23px; }
  @media (min-width: 992px) {
    font-size: 26px; } }

.paddingLadosInicio {
  @media (min-width: 800px) {
    padding-left: 1vw;
    padding-right: 1vw; }
  @media (min-width: 850px) {
    padding-left: 3vw;
    padding-right: 3vw; }
  @media (min-width: 900px) {
    padding-left: 5vw;
    padding-right: 5vw; }
  @media (min-width: 950px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 992px) {
    padding-left: 4vw;
    padding-right: 4vw; }
  @media (min-width: 1050px) {
    padding-left: 6vw;
    padding-right: 6vw; }
  @media (min-width: 1100px) {
    padding-left: 8vw;
    padding-right: 8vw; }
  @media (min-width: 1150px) {
    padding-left: 10vw;
    padding-right: 10vw; }
  @media (min-width: 1200px) {
    padding-left: 12vw;
    padding-right: 12vw; }
  @media (min-width: 1250px) {
    padding-left: 14vw;
    padding-right: 14vw; }
  @media (min-width: 1300px) {
    padding-left: 15vw;
    padding-right: 15vw; }
  @media (min-width: 1350px) {
    padding-left: 16vw;
    padding-right: 16vw; }
  @media (min-width: 1400px) {
    padding-left: 17vw;
    padding-right: 17vw; }
  @media (min-width: 1450px) {
    padding-left: 18vw;
    padding-right: 18vw; }
  @media (min-width: 1500px) {
    padding-left: 19vw;
    padding-right: 19vw; }
  @media (min-width: 1550px) {
    padding-left: 20vw;
    padding-right: 20vw; }
  @media (min-width: 1600px) {
    padding-left: 21vw;
    padding-right: 21vw; }
  @media (min-width: 1650px) {
    padding-left: 22vw;
    padding-right: 22vw; }
  @media (min-width: 1700px) {
    padding-left: 23vw;
    padding-right: 23vw; }
  @media (min-width: 1750px) {
    padding-left: 24vw;
    padding-right: 24vw; }
  @media (min-width: 1800px) {
    padding-left: 25vw;
    padding-right: 25vw; } }


.teditarchica {
  font-size: 13px;
  padding-top: 3px;
  @media (min-width: 500px) {
    font-size: 16px;
    padding-top: 0px; } }

.tBotonActualizar {
  font-size: 14px;
  padding-top: 7px;
  @media (min-width: 500px) {
    font-size: 16px;
    padding-top: 8px; } }

.teditarmediana {
  font-size: 17px;
  @media (min-width: 768px) {
    font-size: 20px; } }

.centradoLead {
  margin: auto; }


.lineaPresupuesto {
  margin-left: 8px;
  width: 270px;
  @media (min-width: 375px) {
    width: 305px; }
  @media (min-width: 768px) {
    width: 290px; } }

.tamanoAun {
  width: 270px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: 320px) {
    width: 280px; }
  @media (min-width: 350px) {
    width: 300px; }
  @media (min-width: 375px) {
    width: 340px; }
  @media (min-width: 500px) {
    width: 450px; } }

// carrito
// boton volador
.volador {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: $rojo;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  @media (min-width: 1000px) {
    bottom: 30px;
    right: 30px; }
  @media (min-width: 1500px) {
    bottom: 40px;
    right: 40px; } }

.botonVolador {
  margin-top: 13px;
  margin-right: 2px; }

// subir-propiedad
.manita {
  cursor: pointer; }

// estadisticas
.testadisticas {
  font-size: 60px;
  padding-top: 100px;
  @media (min-width: 568px) {
    font-size: 60px; } }

.tconstruccion {
  font-size: 25px;
  @media (min-width: 568px) {
    font-size: 25px; } }

// pago
.fondoPago {
  background-color: #0065f9;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }

// inicio
.pizVenta {
  padding-left: 8px; }

// propiedad ID
.posicionCarrousel {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  padding-top: 20px; }

.tamanoImagenCarousel {
  object-fit: cover;
  width: 280px;
  height: 186px;
  @media (min-width: 325px) {
    width: 300px;
    height: 200px; }
  @media (min-width: 350px) {
    width: 325px;
    height: 216px; }
  @media (min-width: 375px) {
    width: 350px;
    height: 233px; }
  @media (min-width: 400px) {
    width: 375px;
    height: 250px; }
  @media (min-width: 500px) {
    width: 400px;
    height: 266px; }
  @media (min-width: 600px) {
    width: 500px;
    height: 333px; }
  @media (min-width: 700px) {
    width: 600px;
    height: 400px; }
  @media (min-width: 850px) {
    width: 700px;
    height: 466px; }
  @media (min-width: 1000px) {
    width: 800px;
    height: 533px; } }

.tamanoCarousel {
  width: 280px;
  @media (min-width: 325px) {
    width: 300px; }
  @media (min-width: 350px) {
    width: 325px; }
  @media (min-width: 375px) {
    width: 350px; }
  @media (min-width: 400px) {
    width: 375px; }
  @media (min-width: 500px) {
    width: 400px; }
  @media (min-width: 600px) {
    width: 500px; }
  @media (min-width: 700px) {
    width: 600px; }
  @media (min-width: 850px) {
    width: 700px; }
  @media (min-width: 1000px) {
    width: 800px; } }

.botonContactarID {
  height: 26px;
  width: 185px; }

.tPrecioID {
  font-size: 17px;
  @media (min-width: 600px) {
    font-size: $grande; }
  @media (min-width: 768px) {
    font-size: $muygrande; } }

.tPrecioID2 {
  font-size: 18px;
  @media (min-width: 600px) {
    font-size: 23px; }
  @media (min-width: 768px) {
    font-size: 28px; } }

.paddingBotonRegresar {
  padding-right: $grande;
  @media (min-width: 576px) {
    padding-right: $muychico; } }

.paddingDescripcion {
  padding-top: 15px;
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 17px;
    padding-top: 0px; } }

.paddingIconosLados {
  @media (min-width: 350px) {
    padding-left: 60px;
    padding-right: 60px; } }

.bannerPremiumColor {
  background-color: #509bf6;
  .btn {
    background: #71b2df;
    border-color: #71b2df; } }
.float-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color:  $verde;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow:  2px 2px 3px #999;
    z-index: 999; }

.favorite-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.heart-in-right {
  position: absolute;
  right: 0;
  width: 20%; }

.add-property {
  display: flex;
  flex-direction: column; }
